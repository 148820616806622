import React, { useEffect, useState } from "react";
import CheckoutStep from "../components/CheckoutStep";
import Shipping from "../components/Shipping";
import Payment from "../components/Payment";
import PlaceOrder from "../components/PlaceOrder";
import { useAppSelector } from "../store/store";
const PlaceOrderScreen=()=>{
    const [currentStep,setCurrentStep]=useState(1)
    const cart=useAppSelector((state)=>state.cart)
  
return (
    <div>
       <div style={{padding:10}}>
       <CheckoutStep current={currentStep} />
       </div>
        {
            currentStep ===1?
            <div>
                <Shipping handleNext={()=>setCurrentStep(currentStep+1)}  handlePrev={()=>setCurrentStep(currentStep-1)} />
                </div> :
                currentStep ===2?
                <div>
<Payment handleNext={()=>setCurrentStep(currentStep+1)}  handlePrev={()=>setCurrentStep(currentStep-1)} />
                    </div> :
                    currentStep ===3&&<PlaceOrder />
        }
    </div>
)
}
export default PlaceOrderScreen;