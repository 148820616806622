import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

const OrderSuccessScreen: React.FC = () =>{
      const { id } = useParams<{ id: string }>();
    const navigate=useNavigate()
    return(
  <Result
    status="success"
    title="Successfully Order"
    subTitle={`Order number: ${id}`}
    extra={[
     
      <Button key="buy" onClick={()=>navigate('/')}>Buy Again</Button>,
    ]}
  />)
};

export default OrderSuccessScreen;