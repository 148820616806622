import React from "react";
import Router from "./Router";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import store from "./store/store";
function App() {
  return (
    <ConfigProvider theme={{token: {
      colorPrimary: "#ec8716",
    },}}>
      <Provider store={store}>
        <Router />{" "}
      </Provider>{" "}
    </ConfigProvider>
  );
}

export default App;
