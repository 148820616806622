import { combineReducers } from "@reduxjs/toolkit";
import product from "./features/productSlice";
import order from "./features/orderSlice";
import auth from "./features/authSlice";
import cart from "./features/cartSlice";
import user from "./features/userSlice";

const reducer = combineReducers({
  product: product,
  order: order,
  auth: auth,
  cart: cart,
  user: user,
});

export default reducer;
