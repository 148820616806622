import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { useAppDispatch } from '../store/store';
import { shipping } from '../store/features/cartSlice';

interface IShipping {
  name:string;
  email:string;
  mobile:string;
  billingAddress: string;
  shippingAddress: string;
}
type ShippingProps={
  handlePrev:()=>void;
  handleNext:()=>void;
}

const Shipping = ({handlePrev,handleNext}:ShippingProps) => {
  const [form] = Form.useForm();
  const dispatch=useAppDispatch();


  const handleSubmit = (values: IShipping) => {
    dispatch(shipping(values))
    handleNext();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSameAsBilling = e.target.checked;
    if (isSameAsBilling) {
      const billingAddressValue = form.getFieldValue('billingAddress');
      form.setFieldsValue({ shippingAddress: billingAddressValue });
    } else {
      form.setFieldsValue({ shippingAddress: '' });
    }
  };

  return (
    <div style={{  margin: 'auto' }}>
      <Form
        form={form}
        name="address_form"
        onFinish={handleSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
           <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter your name' }]}
        >
          <Input  />
        </Form.Item>
        <Form.Item
          label="Mobile"
          name="mobile"
          rules={[{ required: true, message: 'Please enter your mobile' }]}
        >
          <Input  />
        </Form.Item>

        <Form.Item
          label="Email"
          rules={[{ required: true, message: 'Please enter your email' }]}
          name="email"
        >
          <Input  />
        </Form.Item>

        <Form.Item
          label="Billing Address"
          name="billingAddress"
          rules={[{ required: true, message: 'Please enter your billing address' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          label="Shipping Address"
          name="shippingAddress"
          rules={[{ required: true, message: 'Please enter your shipping address' }]}
        >
          <Input.TextArea rows={4} disabled={form.getFieldValue('sameAsBilling')} />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
          valuePropName="checked"
          name="sameAsBilling"
        >
          <Checkbox  >Same as Billing Address</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="default" onClick={handlePrev} htmlType="button">
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Submit & Next
          </Button>
         
        </Form.Item>
      </Form>
    </div>
  );
};

export default Shipping;
