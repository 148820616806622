import { Layout, Input, Button, Flex, Menu, Badge } from "antd";
import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import Login from "./Login";
import { UserOutlined,ShoppingCartOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SearchProps } from "antd/es/input";
import { useAppDispatch, useAppSelector } from "../store/store";
import { accessToken } from "../store/features/authSlice";
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  path?:String,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem("Home", "home",'/'),
  getItem("About", "about",'/about'),
  getItem("Catagories", "catagories",'/catagories'),
  getItem("Contact Us", "contact",'/contact'),
];

const headerStyle: React.CSSProperties = {
  height: 80,
  lineHeight: "80px",
  backgroundColor: "#e6d5c587",
};

const Header = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const navigate=useNavigate();
  const dispatch=useAppDispatch()
  const user=useAppSelector(state=>state.auth)
  const cart=useAppSelector(state=>state.cart)

  useEffect(()=>{
    if(user.user.data?.access_token){
      dispatch(accessToken({access_token:user.user.data?.access_token}))
    }
  },[dispatch, user.user.data?.access_token])


  const onClick: MenuProps["onClick"] = (e) => {
      navigate(e.key==="home" ? '/':e.key)
  };
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => navigate(`/search/${value.trim()}`);


  return (
    <Layout style={headerStyle}>
      <Layout.Header style={headerStyle}>
        <Flex justify="space-between" align="center">
          <div className="logo">
            <img
              style={{ width: 200, height: 50 }}
              src={"/logo.svg"}
              alt="Logo"
            />
          </div>
          <Menu
            style={{
              height: 80,
              lineHeight: "80px",
              backgroundColor: "transparent",
            }}
            onClick={onClick}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["home"]}
            mode="horizontal"
            items={items}
          />
          <Flex justify="center">
            <Input.Search size="large" onSearch={onSearch} placeholder="search" />
          </Flex>
          <Flex justify="center">
            <Button
              type="text"
              icon={<UserOutlined />}
              onClick={() => setIsLoginOpen(true)}
            >
              Login / Register
            </Button>
            <Badge count={cart?.cartItems?.length}>
            <Button shape="circle" onClick={()=>navigate("cart")} icon={<ShoppingCartOutlined />} />
            </Badge>
            <Login
              isOpen={isLoginOpen}
              handleOk={() => setIsLoginOpen(false)}
              handleCancel={() => setIsLoginOpen(false)}
            />
          </Flex>
        </Flex>
      </Layout.Header>
    </Layout>
  );
};

export default Header;
