import React, { useEffect, useState } from 'react';
import { Image, Button, Col, List, Row, Skeleton, Card, Typography } from 'antd';
import { IMAGE_URL } from '../data';
import { useAppDispatch, useAppSelector } from '../store/store';
import { Link, useNavigate } from 'react-router-dom';
import { reset, saveOrder } from '../store/features/orderSlice';

const PlaceOrder = () => {
  const dispatch=useAppDispatch();
  const navigate=useNavigate()
  const order=useAppSelector(state=>state.order)
  const cart=useAppSelector(state=>state.cart)
  useEffect(()=>{
    if(order.orderInfo.data?.id){
        navigate(`/order/success/${order.orderInfo.data.id}`)
        dispatch(reset())
    }
  },[order.orderInfo.data,navigate])

  return (
  <Row gutter={[16,16]} style={{padding:10}}>
    <Col span={14}>
        <Typography.Title>Shipping</Typography.Title>
         <List
    className="cart-list"
    itemLayout="horizontal"
    dataSource={[cart?.shipping]}
    renderItem={(item:any) => (
      < >
        <List.Item>
         <Typography.Text>Name:</Typography.Text>
         <Typography.Text>{item?.name}</Typography.Text>
        </List.Item>
         <List.Item>
         <Typography.Text>Mobile:</Typography.Text>
         <Typography.Text>{item?.mobile}</Typography.Text>
        </List.Item>
         <List.Item>
         <Typography.Text>Email:</Typography.Text>
         <Typography.Text>{item?.email}</Typography.Text>
        </List.Item>
         <List.Item>
         <Typography.Text>Shipping Address:</Typography.Text>
         <Typography.Text>{item?.shippingAddress}</Typography.Text>
        </List.Item>
         <List.Item>
         <Typography.Text>Billing Address:</Typography.Text>
         <Typography.Text>{item?.billingAddress}</Typography.Text>
        </List.Item>
      </>
    )}
  />
        <Typography.Title>Payment</Typography.Title>
    <List
    className="cart-list"
    itemLayout="horizontal"
    dataSource={[cart?.payment]}
    renderItem={(item:any) => (
      < > <List.Item>
         <Typography.Text>Method:</Typography.Text>
         <Typography.Text>{item?.method}</Typography.Text>
        </List.Item>
      </>
        )}
  />
        <Typography.Title>Items</Typography.Title>
    <List
    className="cart-list"
    itemLayout="horizontal"
    dataSource={cart?.cartItems}
    renderItem={(item:any) => (
      <List.Item
      actions={[
          <Typography.Text>{item.addQuantity * item.price}</Typography.Text>]}
      >
          <List.Item.Meta
            avatar={<Image src={IMAGE_URL+item?.image} width={100}  />}
            title={<Link to={`/product/${item.id}`}>{item?.name}</Link>}
          />
            <List.Item.Meta
            title={  <Typography.Text>{item.addQuantity}x{item.price}</Typography.Text>}
          />
        
      </List.Item>
    )}
  />
    </Col>
    <Col span={8}>
      <Card>
        <Typography.Title style={{textAlign:"center"}} >Summary</Typography.Title>
    <List
    className="cart-list"
    itemLayout="horizontal"
    dataSource={[0]}
    renderItem={() => (
      <>
       <List.Item draggable
      >
          <List.Item.Meta
            title="Items Price"
          />
          <Typography.Text>+{cart?.itemsPrice}</Typography.Text>
         
      </List.Item>
      <List.Item draggable
      >
          <List.Item.Meta
            title="Making & Wastage Charge"
          />
          <Typography.Text>+{cart?.itemsMakingWastage}</Typography.Text>
         
      </List.Item>
      <List.Item draggable
      >
          <List.Item.Meta
            title="Tax"
          />
          <Typography.Text>+{cart?.itemsTax}</Typography.Text>
         
      </List.Item>
      <List.Item draggable
      >
          <List.Item.Meta
            title="Discount"
          />
          <Typography.Text>-{cart?.itemsDiscount}</Typography.Text>
         
      </List.Item>
    
      <List.Item draggable
      >
          <List.Item.Meta
            title="Total Price"
          />
          <Typography.Text>{cart?.totalPrice}</Typography.Text>
         
      </List.Item>
      </>
    )}
  />
   <Button block onClick={()=>{
    dispatch(saveOrder({
       id: "new",
  subtotal: cart?.itemsPrice,
  tax: cart?.itemsTax,
  discount: cart?.itemsDiscount,
  total: cart?.totalPrice,
  customer: {
    userId:"661fe2bde4f2a7fd2c86e92d",
    name:cart.shipping.name,
    mobile:cart.shipping.mobile,
    email:cart.shipping.email,
    invoiceAddress:cart.shipping.billingAddress,
    shippingAddress:cart.shipping.shippingAddress,
  },
  products:cart.cartItems.map((res:any)=>{
  return {
    productId: res.id,
  quantity: res.addQuantity,
  price: res.price,
  total: res.price * res.addQuantity,
  }
  }),
  payment: {
method:cart.payment.method
  }
    }))
   }} type="primary">Order</Button>
   </Card>
    </Col>
  </Row>
  )
}

export default PlaceOrder