import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import IndexLayout from "./components/layout/IndexLayout";
import HomeScreen from "./screens/HomeScreen";
import ProductCategoriesScreen from "./screens/ProductCategoriesScreen";
import ProductScreen from "./screens/ProductScreen";
import ProductListScreen from "./screens/ProductListScreen";
import CartScreen from "./screens/CartScreen";
import OrderScreen from "./screens/OrderScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderSuccessScreen from "./screens/OrderSuccessScreen";

const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <IndexLayout />,
      children: [
        {
          path: "/",
          element: <HomeScreen />,
        },
        {
          path: "/product-categories",
          element: <ProductCategoriesScreen />,
        },
        {
          path: "/product",
          element: <ProductListScreen />,
        },
        {
          path: "/search/:keyword",
          element: <ProductListScreen />,
        },
        {
          path: "/product/:id",
          element: <ProductScreen />,
        },
        {
          path: "/placeorder",
          element: <PlaceOrderScreen />,
        },
        {
          path: "/cart",
          element: <CartScreen />,
        },
        {
          path: "/order/success/:id",
          element: < OrderSuccessScreen/>,
        },
        {
          path: "/order",
          element: <OrderScreen />,
        },
      ],
    },
    {
      path:"*",element:<NotFoundScreen />
    }
  ]);
  return <RouterProvider router={router} />;
};

export default Router;
