import axios from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProductType, ProductsType } from "../types/ProductType";
import { API_URL } from "../../data";
import { ProductCategoriesType } from "../types/ProductCategories";
import { BannerType } from "../types/BannerType";
import { RootState } from "../store";

/**
 * Get products from server
 */
export const getProducts = createAsyncThunk<
  ProductsType,
  { keyword: string; pageNumber: number }
>("product/getProducts", async ({ keyword, pageNumber }, { getState }) => {
  const AppState = getState() as RootState;
  const product = AppState.product.products.data as ProductsType;
  const response = await axios.get(`${API_URL}product`, {
    params: {
      keyword: keyword,
      pageNumber: pageNumber,
    },
  });
  const data = (await response.data) as ProductsType;
  return [...product, ...data];
});

export const getProductByID = createAsyncThunk<
  ProductType,
  { id: string | undefined }
>("product/getProductByID", async ({ id }) => {
  const response = await axios.get(`${API_URL}product/${id}`);
  const data = (await response.data) as ProductType;
  return data;
});

export const getProductCategories = createAsyncThunk<ProductCategoriesType[]>(
  "product/getProductCategories",
  async () => {
    const response = await axios.get(`${API_URL}product/categories`);
    const data = (await response.data) as ProductCategoriesType[];
    return data;
  }
);

export const getBanner = createAsyncThunk<BannerType[]>(
  "product/getBanners",
  async () => {
    const response = await axios.get(`${API_URL}product/banners`);
    const data = (await response.data) as BannerType[];
    return data;
  }
);

export interface ProductState {
  products: {
    data: ProductsType;
    loading: boolean;
    error: string;
  };
  categories: {
    data: ProductCategoriesType[];
    loading: boolean;
    error: string;
  };
  product: {
    data: ProductType;
    loading: boolean;
    error: string;
  };
  banner: {
    data: BannerType[];
    loading: boolean;
    error: string;
  };
}

const initialState: ProductState = {
  products: {
    data: [],
    loading: true,
    error: "",
  },
  categories: {
    data: [],
    loading: true,
    error: "",
  },
  product: {
    data: null,
    loading: true,
    error: "",
  },
  banner: {
    data: [],
    loading: true,
    error: "",
  },
};

export const productsSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.products.loading = true;
        state.products.data = [];
      })
      .addCase(
        getProducts.fulfilled,
        (state, action: PayloadAction<ProductsType>) => {
          state.products.loading = false;
          state.products.data = action.payload;
        }
      )
      .addCase(getProducts.rejected, (state, action) => {
        state.products.loading = false;
        state.products.data = [];
        state.products.error = action.error.message || "Something went wrong";
      })
      .addCase(getProductByID.pending, (state) => {
        state.product.loading = true;
        state.product.data = null;
      })
      .addCase(
        getProductByID.fulfilled,
        (state, action: PayloadAction<ProductType>) => {
          state.product.loading = false;
          state.product.data = action.payload;
        }
      )
      .addCase(getProductByID.rejected, (state, action) => {
        state.product.loading = false;
        state.product.data = null;
        state.product.error = action.error.message || "Something went wrong";
      })

      .addCase(getProductCategories.pending, (state) => {
        state.categories.loading = true;
        state.categories.data = [];
      })
      .addCase(
        getProductCategories.fulfilled,
        (state, action: PayloadAction<ProductCategoriesType[]>) => {
          state.categories.loading = false;
          state.categories.data = action.payload;
        }
      )
      .addCase(getProductCategories.rejected, (state, action) => {
        state.categories.loading = false;
        state.categories.data = [];
        state.categories.error = action.error.message || "Something went wrong";
      })

      .addCase(getBanner.pending, (state) => {
        state.banner.loading = true;
        state.banner.data = [];
      })
      .addCase(
        getBanner.fulfilled,
        (state, action: PayloadAction<BannerType[]>) => {
          state.banner.loading = false;
          state.banner.data = action.payload;
        }
      )
      .addCase(getBanner.rejected, (state, action) => {
        state.banner.loading = false;
        state.banner.data = [];
        state.banner.error = action.error.message || "Something went wrong";
      });
    // You can add more cases here similar to the provided example, e.g. removeProducts
  },
});

export default productsSlice.reducer;
