import React from 'react';
import { Card, Descriptions, Button, Image } from 'antd';
import { useAppSelector } from '../store/store';
import { IMAGE_URL } from '../data';


const OrderScreen: React.FC= () => {

  const cart=useAppSelector(state=>state.cart)

  const handleConfirmOrder = () => {
    // Placeholder for handling order confirmation
    console.log('Order confirmed!');
  };

  return (
    <div style={{  margin: 'auto' }}>
      <h1>Order Summary</h1>
      <Card style={{ marginBottom: 16 }}>
        <h3>Shipping Address</h3>
      {cart ?.address?.shippingAddress}
      <h3>Billing Address</h3>
      {cart ?.address?.billingAddress}
      </Card>
      {cart?.cartItems.map((product:any, index:number) => (
        <Card key={index} style={{ marginBottom: 16 }}>
          <div style={{ display: 'flex' }}>
            <Image src={IMAGE_URL+product.image} alt={product.name} width={100} height={100} />
            <Descriptions title={product.name} bordered style={{ marginLeft: 16, flex: 1 }}>
              <Descriptions.Item label="ID">{product.id}</Descriptions.Item>
              <Descriptions.Item label="Price">₹{product.price}</Descriptions.Item>
              <Descriptions.Item label="Quantity">{product.addQuantity}</Descriptions.Item>
            </Descriptions>
          </div>
        </Card>
      ))}
      <Card>
        <Descriptions bordered>
          <Descriptions.Item label="Total Price">₹{cart?.totalPrice}</Descriptions.Item>
        </Descriptions>
      </Card>
      <div style={{ textAlign: 'center', marginTop: 16 ,marginBottom:16 }}>
        <Button type="primary" onClick={handleConfirmOrder}>
          Confirm Order
        </Button>
      </div>
    </div>
  );
};

export default OrderScreen;
