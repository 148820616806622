import { createSlice } from "@reduxjs/toolkit";
import { ProductType } from "../types/ProductType";
import { updateCart } from "../../utils/cartUtils";

const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart") as string)
  : { cartItems: [], shipping: {}, payment: { method: "COD" } };

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { ...item } = action.payload;
      const existItem = state.cartItems.find(
        (x: ProductType) => x?.id === item.id
      );
      if (existItem) {
        state.cartItems = state.cartItems.map((x: ProductType) =>
          x?.id === existItem.id ? item : x
        );
      } else {
        state.cartItems = [...state.cartItems, item];
      }
      updateCart(state);
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (x: ProductType) => x?.id !== action.payload
      );
      return updateCart(state);
    },
    shipping: (state, action) => {
      state.shipping = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    payment: (state, action) => {
      state.payment = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
  },
});

export const { addToCart, removeFromCart, shipping, payment } =
  cartSlice.actions;

export default cartSlice.reducer;
