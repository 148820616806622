import React, { Fragment, useEffect, useState } from "react";
import {
  Carousel as AntCarousel,
  Button,
  Card,
  Col,
  Flex,
  Row,
  Spin,
  Typography,
} from "antd";
import { useAppDispatch, useAppSelector } from "../store/store";
import { useNavigate } from "react-router-dom";
import { getProductCategories } from "../store/features/productSlice";
import { ProductCategoriesType } from "../store/types/ProductCategories";

const Categories = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const products = useAppSelector((state) => state.product);
  useEffect(() => {
    dispatch(getProductCategories()).then(() => setLoading(false));
  }, [dispatch]);
  return (
    <Fragment>
      <Spin spinning={loading}>
        <Flex justify="space-between">
          <Typography.Title underline>Categories</Typography.Title>
          <Button type="default">View All</Button>
        </Flex>
        <AntCarousel autoplay effect="fade">
          {products.categories.data.length > 0 &&
            Array.from(
              {
                length: Math.round(
                  (products.categories.data.length + 1 - 1 + 1) / 4
                ),
              },
              (_, i) => 1 + i
            ).map((res, row) => {
              return (
                <div>
                  <Row gutter={50}>
                    {products.categories.data
                      .slice(row * 4, (row + 1) * 4)
                      .map((category: ProductCategoriesType, key: number) => {
                        return (
                          <Col key={key} span={6}>
                            <Card
                              onClick={() =>
                                navigate(`/product`, {
                                  state: { keyword: category.id },
                                })
                              }
                              hoverable
                              cover={
                                <img alt={category.name} src={category.image} />
                              }
                            >
                              <Card.Meta title={category.name} />
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              );
            })}
        </AntCarousel>
      </Spin>
    </Fragment>
  );
};

export default Categories;
