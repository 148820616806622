import React, { useState } from 'react';
import Shipping from './Shipping';
import { Steps } from 'antd';

const steps = [
  {
    title: 'Login',
  },
  {
    title: 'Shipping',
  },
  {
    title: 'Payment',
  }, {
    title: 'Place Order',
  },
];
type CheckoutProps={
  current:number;
}

const CheckoutStep = ({current}:CheckoutProps) => {


  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  

  return (
    <>
      <Steps current={current} items={items} />
      
    </>
  );
};

export default CheckoutStep;