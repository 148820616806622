import axios from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../data";
import { RootState } from "../store";
import UserType from "../types/UserType";

export const saveUser = createAsyncThunk<
  UserType,
  { displayName: string | undefined,email: string | undefined,password: string | undefined,type:string }
>("user/createUser", async ({ displayName,email,password,type }) => {
  const response = await axios.put(`${API_URL}user/${type}`,{data:{displayName,email},password});
  const data = (await response.data) as any;
  return data;
});

type userState={
    userInfo:{
        data:any,
        loading:boolean,
        error:string
    }
}

const initialState: userState = {
    userInfo: {
      data: null,
      loading: false,
      error: "",
    },
   
  };
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(saveUser.pending, (state) => {
          state.userInfo.loading = true;
          state.userInfo.data = {};
        })
        .addCase(
          saveUser.fulfilled,
          (state, action: PayloadAction<UserType>) => {
            state.userInfo.loading = false;
            state.userInfo.data = action.payload;
          }
        )
        .addCase(saveUser.rejected, (state, action) => {
          state.userInfo.loading = false;
          state.userInfo.data = {};
          state.userInfo.error = action.error.message || "Something went wrong";
        })
       
       
      // You can add more cases here similar to the provided example, e.g. removeProducts
    },
  });
  
  export default userSlice.reducer;