import React from 'react'
import { Layout, Input, Button, MenuProps } from "antd";
import { backgroundImage_URL } from "../data";



type MenuItem = Required<MenuProps>["items"][number];

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  backgroundImage:`url(${backgroundImage_URL})`, 
};




const Footer = () => {



  return (
    <Layout.Footer style={footerStyle}>
      <div className="footer-container"/>
        <div className='copy-rights' style={{ textAlign: 'center' }}>
            <p>Copyright © 2024 - Divine Jewelry - Powered by <a href='https://techslideitsolutions.com/'>TechSlide ITS</a></p>
            </div>
      
Footer
    </Layout.Footer>
  )
}

export default Footer