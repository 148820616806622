import React, { useEffect, useState } from 'react';
import { Image, Button, Col, List, Row, Skeleton, Card, Typography } from 'antd';
import { IMAGE_URL } from '../data';
import { useAppDispatch, useAppSelector } from '../store/store';
import { Link, useNavigate } from 'react-router-dom';
import {DeleteOutlined} from "@ant-design/icons"
import { removeFromCart } from '../store/features/cartSlice';

const CartScreen = () => {
  const dispatch=useAppDispatch();
  const navigate=useNavigate()
  const cart=useAppSelector(state=>state.cart)


  return (
  <Row gutter={[16,16]} style={{padding:10}}>
    <Col span={14}>
    <List
    className="cart-list"
    itemLayout="horizontal"
    dataSource={cart?.cartItems}
    renderItem={(item:any) => (
      <List.Item
        actions={[<Button type="dashed" onClick={()=>dispatch(removeFromCart(item?.id))} icon={<DeleteOutlined />} />]}
      >
          <List.Item.Meta
            avatar={<Image src={IMAGE_URL+item?.image} width={200}  />}
            title={<Link to={`/product/${item.id}`}>{item?.name}</Link>}
            description={item?.description}
          />
      </List.Item>
    )}
  />
    </Col>
    <Col span={8}>
      <Card>
        <Typography.Title style={{textAlign:"center"}} >Summary</Typography.Title>
    <List
    className="cart-list"
    itemLayout="horizontal"
    dataSource={[0]}
    renderItem={() => (
      <>
       <List.Item draggable
      >
          <List.Item.Meta
            title="Items Price"
          />
          <Typography.Text>+{cart?.itemsPrice}</Typography.Text>
         
      </List.Item>
      <List.Item draggable
      >
          <List.Item.Meta
            title="Making & Wastage Charge"
          />
          <Typography.Text>+{cart?.itemsMakingWastage}</Typography.Text>
         
      </List.Item>
      <List.Item draggable
      >
          <List.Item.Meta
            title="Tax"
          />
          <Typography.Text>+{cart?.itemsTax}</Typography.Text>
         
      </List.Item>
      <List.Item draggable
      >
          <List.Item.Meta
            title="Discount"
          />
          <Typography.Text>-{cart?.itemsDiscount}</Typography.Text>
         
      </List.Item>
    
      <List.Item draggable
      >
          <List.Item.Meta
            title="Total Price"
          />
          <Typography.Text>{cart?.totalPrice}</Typography.Text>
         
      </List.Item>
      </>
    )}
  />
   <Button block onClick={()=>navigate("/placeorder")} type="primary">Buy Now</Button>
   </Card>
    </Col>
  </Row>
  )
}

export default CartScreen