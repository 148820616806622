import React, { useEffect } from "react";
import { Modal, Tabs, Button, Checkbox, Form, Input, Space } from "antd";
import type { TabsProps } from "antd";
import { useAppDispatch, useAppSelector } from "../store/store";
import { login } from "../store/features/authSlice";
import { saveUser } from "../store/features/userSlice";


type HandleType = {
  isOpen?: boolean;
  handleOk?: () => void | undefined;
  handleCancel?: () => void | undefined | null;
  handleNext?: () => void | undefined;
};

const onChange = (key: string) => {
  console.log(key);
};


const LoginTab = ({handleCancel}:HandleType) => {
  const [form] = Form.useForm();

  type FieldType = {
    email: string;
    password: string;
  };
  const onFinish = (values: FieldType) => {
    login(values.email,values.password).then((res)=>{
     
      handleCancel &&  handleCancel();
      
    }).catch((_errors: { type: 'email' | 'password' | `root.${string}` | 'root'; message: string }[]) => {
      _errors.forEach((error) => {
        form.setFields([{name:error.type,errors:[error.message]}])
      });
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item<FieldType>
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Please input your email!" },
          { type: "email", message: "Invalid Email" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FieldType>
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

const RegisterTab = ({handleCancel}:HandleType) => {
  const dispatch=useAppDispatch()
  const {userInfo}=useAppSelector(state=>state.user)
  const [form] = Form.useForm();

  type FieldType = {
    email: string;
    password: string;
    displayName: string;
  };
  const onFinish = (values: FieldType) => {
    dispatch(saveUser({displayName:values.displayName,email:values.email,password:values.password,type:'new'}))  
  };

  useEffect(()=>{
    if(userInfo.data){
      handleCancel &&  handleCancel();
    }
    if(userInfo.error){

    }
  },[userInfo])

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

 

  return (
    <Form
    form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item<FieldType>
        label="Name"
        name="displayName"
        rules={[{ required: true, message: "Please input your Name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FieldType>
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Please input your email!" },
          { type: "email", message: "Invalid Email" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FieldType>
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button loading={userInfo.loading} htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};



const Login = ({ isOpen, handleOk, handleCancel }: HandleType) => {
  return (
    <Modal
      footer={null}
      title="Login/Register"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Tabs
        defaultActiveKey="1"
        style={{ width: "100%" }}
        items={[
          {
            key: "1",
            label: "Login",
            children: (
              <Space>
                <LoginTab handleCancel={handleCancel} />
              </Space>
            ),
          },
          {
            key: "2",
            label: "Register",
            children: (
              <Space>
                <RegisterTab handleCancel={handleCancel} />
              </Space>
            ),
          },
        ]}
        onChange={onChange}
      />
    </Modal>
  );
};

export default Login;
