import React, { Fragment } from "react";
import Carousel from "../components/Carousel";
import Catagories from "../components/Categories";
import About from "../components/About";
import { Layout } from "antd";
import Product from "../components/Product";

const HomeScreen = () => {
  return (
    <Fragment>
      <Carousel />
      <Layout.Content
        style={{ backgroundColor: "#ffffff00", padding: "10px 50px" }}
      >
        <Catagories />
        <About />
        
        <Product />
      </Layout.Content>
    </Fragment>
  );
};

export default HomeScreen;
