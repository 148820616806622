import React from "react";
import Header from "../Header";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import { Layout } from "antd";

const IndexLayout = () => {
  return (
    <Layout style={{padding:0,margin:0}}>
      <Header />
      <Layout.Content style={{minHeight:'80vh'}}>
        <Outlet />
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default IndexLayout;
