import axios, { AxiosResponse } from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../data";
import UserType from "../types/UserType";

function _setSession(access_token: string | null) {
  if (access_token) {
    setAccessToken(access_token);
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    removeAccessToken();
    delete axios.defaults.headers.common.Authorization;
  }
}

/**
 * Sets the access token in the local storage.
 */
function setAccessToken(access_token: string) {
  return window.localStorage.setItem("jwt_access_token", access_token);
}

/**
 * Removes the access token from the local storage.
 */
function removeAccessToken() {
  return window.localStorage.removeItem("jwt_access_token");
}

/**
 * Get products from server
 */
type LoginResponse = {
  user: UserType;
  access_token: string;
  error?: {
    type: "email" | "password" | `root.${string}` | "root";
    message: string;
  }[];
} | null;

export const login =  (email:string, password:string ) => {
 return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}auth/login`, {
        email,
        password
      })
      .then(
        (
          response: AxiosResponse<{
            user: UserType;
            access_token: string;
            error?: {
              type: 'email' | 'password' | `root.${string}` | 'root';
              message: string;
            }[];
          }>
        ) => {
          if (response.data.user) {
            _setSession(response.data.access_token);
           // this.emit('onLogin', response.data.user);
            resolve(response.data.user);
          } else {
            // console.log(response);
            reject(response.data.error);
          }
        }
      );
  });
};

export const accessToken = createAsyncThunk<
  LoginResponse,
  { access_token: string }
>("auth/access-token", async ({ access_token }) => {
  const response = await axios.post(`${API_URL}auth/access-token`, {
    access_token,
  });
  const data = (await response.data) as LoginResponse;
  localStorage.setItem('userInfo',JSON.stringify(data))
  _setSession(data ? data.access_token : null);
  return data;
});

export interface AuthState {
  user: {
    loading: boolean;
    data: LoginResponse;
    error: string;
  };
}

const initialState: AuthState = {
  user: {
    loading: true,
    data: null,
    error: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(accessToken.pending, (state) => {
        state.user.loading = true;
        state.user.data = null;
      })
      .addCase(
        accessToken.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.user.loading = false;
          state.user.data = action.payload;
        }
      )
      .addCase(accessToken.rejected, (state, action) => {
        state.user.loading = false;
        state.user.data = null;
        state.user.error = action.error.message || "Something went wrong";
      });

    // You can add more cases here similar to the provided example, e.g. removeProducts
  },
});

export default authSlice.reducer;
