
export const addDecimals = (num: number) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state: any) => {
  const itemsPrice = state.cartItems.reduce(
    (acc: number, item: any) =>
      acc + (item.price * 100 * item.addQuantity) / 100,
    0
  );
  const itemsDiscount = state.cartItems.reduce(
    (acc: number, item: any) =>
      acc + (item.discount),
    0
  );
  const itemsMakingWastage = state.cartItems.reduce(
    (acc: number, item: any) =>
      acc + (item.makingCharge+item.wastageCharge),
    0
  );
  const itemsTax = state.cartItems.reduce(
    (acc: number, item: any) =>
      acc + (item.taxRate),
    0
  );
  state.itemsPrice = addDecimals(itemsPrice);
  state.itemsDiscount = addDecimals(itemsDiscount);
  state.itemsMakingWastage = addDecimals(itemsMakingWastage);
  state.itemsTax = addDecimals(itemsTax);

  const totalPrice = itemsPrice-itemsDiscount+itemsMakingWastage+itemsTax;
  // Calculate the total price
  state.totalPrice = addDecimals(totalPrice);
  // Save the cart to localStorage
  localStorage.setItem("cart", JSON.stringify(state));

  return state;
};
