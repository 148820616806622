import React, { useEffect, useState } from "react";
import { Carousel as AntCarousel, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../store/store";
import { useNavigate } from "react-router-dom";
import { getBanner } from "../store/features/productSlice";
import { BannerType } from "../store/types/BannerType";
import { IMAGE_URL } from "../data";

const contentStyle: React.CSSProperties = {
  height: "90vh",
  color: "#fff",
  lineHeight: "50vh",
  textAlign: "center",
  background: "#e6d5c587",
};
const Carousel = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const products = useAppSelector((state) => state.product);
   useEffect(() => {
    dispatch(getBanner()).then(() => setLoading(false));
  }, [dispatch]);
  return (
    <Spin spinning={loading}>
    <AntCarousel dots={{className:"dots"}} autoplay effect="fade">
    {products.banner.data.length > 0 && products.banner.data.map((res:BannerType,index:number)=>{
      if(res.active){
        return <div key={index}>
        <img onClick={()=>navigate(res.url)} style={{width:"100%",height:"60vh"}} src={`${IMAGE_URL}${res?.image}`} />
      </div>
      }
    

    })
     }
    </AntCarousel>
    </Spin>
  );
};

export default Carousel;
