import React from 'react';
import { Form, Input, Button, Checkbox,Select } from 'antd';
import { useAppDispatch } from '../store/store';
import { payment } from '../store/features/cartSlice';
const { Option } = Select;
type IPayment={

}
type ShippingProps={
    handlePrev:()=>void;
    handleNext:()=>void;
  }
  
const Payment = ({handlePrev,handleNext}:ShippingProps) => {
   const [form] = Form.useForm();
  const dispatch=useAppDispatch();


  const handleSubmit = (values: IPayment) => {
    dispatch(payment(values))
    handleNext();
  };
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Form
        form={form}
        name="address_form"
        onFinish={handleSubmit}
      >
        <Form.Item name="method" label="Payment">
<Select>
                <Option value="COD">COD</Option>
            </Select>
        </Form.Item>
            
            <Form.Item >
        <Button type="default" onClick={handlePrev} htmlType="button">
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Submit & Next
          </Button>
         
        </Form.Item>
        </Form>
        </div>
    );
}

export default Payment;
