import React, { useEffect, useState } from "react";
import { Button, Card, Col, Empty, Flex, Image, Row, Spin, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "../store/store";
import { getProducts } from "../store/features/productSlice";
import { ProductType } from "../store/types/ProductType";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ShoppingCartOutlined,ShoppingOutlined} from "@ant-design/icons"
import { IMAGE_URL } from "../data";
import { addToCart } from "../store/features/cartSlice";
const Product = () => {
  const [loading, setLoading] = useState(true);
  const [pageNumber,setPageNumber]=useState(1)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {keyword:urlKeyword} = useParams();
  const products = useAppSelector((state) => state.product);
  useEffect(() => {
    dispatch(
      getProducts({ keyword: urlKeyword || '', pageNumber: pageNumber })
    ).then(()=>{
      setLoading(false)
    
    });
  }, [dispatch,  pageNumber]);

  return (
    <div style={{ padding: "10px 50px" }}>
      <Spin spinning={loading}>
      <Flex justify="space-between">
          <Typography.Title underline>Products</Typography.Title>
          <Button type="default" onClick={()=>{  dispatch(getProducts({ keyword:  'all', pageNumber: pageNumber }))}}>View All</Button>
        </Flex>
        <Row gutter={[16,16]}>
          {products.products.data.length > 0 &&
            products.products.data.map((res: ProductType, index: number) => {
              return (
                <Col key={index} span={6}>
                  <Card
                    style={{border:"2px solid grey"}}
                    hoverable
                    cover={
                      <Flex vertical justify="center" align="center" style={{padding:10}}>
                       <Typography.Title style={{textAlign:"center"}}>{res?.name}</Typography.Title>
                       <Image
                        style={{ maxHeight: 200 }}
                        alt={res?.name}
                        src={`${IMAGE_URL}${res?.image}`}
                      />
                      </Flex>
                      
                    }
                  >
                    
                     
                   <Flex align="center" justify="center">
                   <Typography.Title style={{textAlign:"center"}}>Price :{res?.price}</Typography.Title>
                   </Flex>
                   <Flex align="center" justify="center">
                   <Typography.Text style={{textAlign:"center"}}>Discount :{res?.discount}</Typography.Text>
                   </Flex>
                    <Button size="large" block style={{marginTop:10}} onClick={() => navigate(`/product/${res?.id}`)} icon={<ShoppingOutlined />} type="primary" >Buy Now</Button>
                  </Card>
                </Col>
              );
            })}
        </Row>
        {products.products.data.length === 0 ? (
          <Row justify="center">
            <Empty />
          </Row>
        ):products.products.data.length > 9 && <Row justify="center">
          <Button type="primary" style={{marginTop:10}} onClick={()=>setPageNumber(pageNumber+1)} >Load more</Button></Row>}
      </Spin>
    </div>
  );
};

export default Product;
