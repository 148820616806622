import React, { Fragment } from 'react'
import Product from '../components/Product'

const ProductListScreen = () => {
  return (
    <Fragment>
      <Product />
    </Fragment>
  )
}

export default ProductListScreen