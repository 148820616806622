import { Button, Col, Flex, Row, Space, Typography } from "antd";
import React from "react";

const About = () => {
  return (
    <Space direction="vertical" className="py-3">
      <Typography.Title underline>About</Typography.Title>

      <Row gutter={10}>
        <Col span={16}>
          <Typography.Paragraph>
            In ecommerce, there is a greater emphasis on visual content than in
            other sectors because in nearly every case, buyers want to see what
            they are buying—and in as much detail as possible. A video or still
            image of a product in use gives the buyer a good indication of
            quality and suitability. This is why many of the best ecommerce
            marketers focus their content marketing efforts exclusively on
            producing high-quality videos. A good example is U.S. grooming brand
            Beardbrand, which grew quickly through smart content marketing.
            Specifically, this meant founder Eric Bandholz spent time and effort
            creating video content for each part of the acquisition funnel
          </Typography.Paragraph>
          <Button type="default">Read More</Button>
        </Col>
        <Col span={8}></Col>
      </Row>
    </Space>
  );
};

export default About;
