import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Descriptions, Image, InputNumber, Spin,Button, Flex, Grid, Col, Row, Typography, Form, Input } from 'antd';
import { useAppDispatch, useAppSelector } from '../store/store';
import { getProductByID } from '../store/features/productSlice';
import { IMAGE_URL } from '../data';
import { addToCart } from '../store/features/cartSlice';


const ProductScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate=useNavigate()

  const {product} =useAppSelector(state=>state.product)
  const dispatch=useAppDispatch()

  const [quantity, setQuantity] = React.useState<number>(1);

  React.useEffect(() => {
    dispatch(getProductByID({id:id}))
  }, [id,dispatch]);

  const handleQuantityChange = (value: number | null) => {
    if(value !==null){
    setQuantity(value);
    }
  };

  const handleAddToCart = () => {
    // Implementation to add the product to the cart
    dispatch(addToCart({...product.data,addQuantity:quantity}))
    navigate("/cart")
  };



  return (
    <Spin spinning={product.loading}>
      {product?.data && (
        <Row gutter={[16,16]} style={{padding:50}}>
       <Col span={10} style={{padding:50}}>
       <Image  src={IMAGE_URL+product?.data.image} alt={product?.data?.name} width="100%"  />
       </Col>
       <Col span={14} style={{padding:50}}>
        <Form>
       <Typography.Title>{product?.data?.name}</Typography.Title>
       <Typography.Title>{product?.data?.price}</Typography.Title>
       <Form.Item label="Quantity">
       <InputNumber  min={1} onChange={handleQuantityChange} defaultValue={1} value={quantity} max={product?.data?.quantity} />
       </Form.Item>
       
         <Flex style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
           <Button block size="large" type="default" onClick={handleAddToCart} style={{ marginRight: '10px' }}>
           Buy Now
            </Button>
            
         </Flex>
       </Form>
       </Col>
        </Row>
        // <Card title={product?.data?.name}>
        //   <div style={{ display: 'flex', justifyContent: 'center' }}>
        //     <Image src={IMAGE_URL+product?.data.image} alt={product?.data?.name} width={200} />
        //   </div>
        //   <Descriptions bordered>
        //     <Descriptions.Item label="ID">{product?.data?.id}</Descriptions.Item>
        //     <Descriptions.Item label="Name">{product?.data?.name}</Descriptions.Item>
        //     <Descriptions.Item label="Description">
        //       {product?.data?.description}
        //     </Descriptions.Item>
        //     <Descriptions.Item label="Price">{product?.data?.price}</Descriptions.Item>
        //     <Descriptions.Item label="Quantity">
        //       <InputNumber min={1} onChange={handleQuantityChange} defaultValue={1} value={quantity} max={product?.data?.quantity}  />
        //     </Descriptions.Item>
        //   </Descriptions>
        //   <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        //     <Button type="primary" onClick={handleAddToCart} style={{ marginRight: '10px' }}>
        //       Add to Cart
        //     </Button>
        //     <Button type="primary" onClick={handleBuyNow}>
        //       Buy Now
        //     </Button>
        //   </div>
        // </Card>
      ) }
    </Spin>
  );
};

export default ProductScreen;
