import axios from "axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../data";
import OrderType from "../types/OrderType";

export const saveOrder = createAsyncThunk<OrderType, OrderType>(
  "order/createOrder",
  async (value: any) => {
    const response = await axios.put(`${API_URL}order/${value?.id}`, value);
    const data = (await response.data) as any;

    return data;
  }
);

type orderState = {
  orderInfo: {
    data: any;
    loading: boolean;
    error: string;
  };
};

const initialState: orderState = {
  orderInfo: {
    data: null,
    loading: false,
    error: "",
  },
};
export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset: (state) => {
      localStorage.removeItem("cart");
      state.orderInfo = { data: null, loading: false, error: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveOrder.pending, (state) => {
        state.orderInfo.loading = true;
        state.orderInfo.data = {};
      })
      .addCase(
        saveOrder.fulfilled,
        (state, action: PayloadAction<OrderType>) => {
          state.orderInfo.loading = false;
          state.orderInfo.data = action.payload;
        }
      )
      .addCase(saveOrder.rejected, (state, action) => {
        state.orderInfo.loading = false;
        state.orderInfo.data = {};
        state.orderInfo.error = action.error.message || "Something went wrong";
      });

    // You can add more cases here similar to the provided example, e.g. removeProducts
  },
});
export const { reset } = orderSlice.actions;
export default orderSlice.reducer;
