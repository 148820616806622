import { configureStore } from "@reduxjs/toolkit";
import logger from 'redux-logger'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import reducer from "./rootReducer";

const store = configureStore({
  reducer: reducer,
  middleware:(getDefaultMiddleware)=>
    getDefaultMiddleware().prepend().concat(logger)
  
});
export type RootState = ReturnType<typeof store.getState>;
/**
 * Typed hook to get the dispatch function from the Redux store.
 */
// export const useAppDispatch: () => AppDispatchType = useDispatch;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;

/**
 * Typed hook to get a slice of the Redux store state.
 * T - The type of the slice of state to retrieve.
 */
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;

export default store;
